import clsx from 'clsx';
import { Link } from '@theme/components';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { ROUTES } from '@theme/routes';

export default function PopulerSearchKeywords() {
  const { data } = useGetWidgetQuery('search-popular-keywords');

  const attributes = data?.attributes;

  if (Object.keys(attributes || {}).length === 0) return null;

  const keywords = attributes?.keywords;

  if (!Array.isArray(keywords) || !keywords?.length) return null;

  const title = attributes?.title?.value;

  return (
    <div className={clsx('px-4', 'lg:px-6')}>
      {title && (
        <h4 className={clsx('mb-4 text-lg font-medium', 'lg:text-2xl')}>
          {title}
        </h4>
      )}
      <div className="flex flex-wrap gap-3">
        {keywords.map((keyword, index) => {
          const keywordValue = keyword.value.keyword;
          if (!keywordValue) return;
          return (
            <Link
              href={`${ROUTES.LIST}?search_text=${keywordValue}`}
              key={`search-keyword-${index}`}
              className={clsx(
                'min-w-[120px] border border-primary px-3 py-1.5 text-center text-sm text-primary',
                'lg:px-4 lg:py-2 lg:text-base'
              )}
            >
              {keywordValue}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
