'use client';

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeSearch, openSearch } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { Button, Icon, Link } from '@theme/components';
import Results from './results';
import { ROUTES } from '@theme/routes';
import { useLocalization, useRouter } from '@akinon/next/hooks';

import PopulerSearchKeywords from '@theme/widgets/search/populer-keywords';
import SearchModalRecommendation from '@theme/widgets/search/recommendation';

export default function Search() {
  const { t } = useLocalization();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefMobile = useRef<HTMLInputElement>(null);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  useEffect(() => {
    if (isSearchOpen) {
      inputRefMobile.current?.focus();
      document.body.style.overflow = 'hidden';
    }

    setSearchHistory(JSON.parse(localStorage.getItem('searchHistory') || '[]'));
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSearchOpen]);

  const removeSearchHistory = (index: number) => {
    const searchHistory = JSON.parse(
      localStorage.getItem('searchHistory') || '[]'
    );
    searchHistory.splice(index, 1);
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    setSearchHistory(searchHistory);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText.trim() !== '') {
      router.push(`${ROUTES.LIST}/?search_text=${searchText}`);
      dispatch(closeSearch());
      setSearchText('');
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <div
        className={clsx(
          'hidden  w-full min-w-56 max-w-[636px] flex-col items-center gap-2 self-center lg:w-full',
          'lg:flex'
        )}
      >
        <div
          className={clsx(
            'relative flex h-10 w-full items-center gap-4 bg-gray-50 px-6 py-2',
            'ring-primary [&:has(:focus-visible)]:ring-1'
          )}
          onClick={() => dispatch(openSearch())}
        >
          <Icon className="h-6 w-6 text-gray-800" name="search" size={24} />
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              handleOnKeyDown(e);
            }}
            placeholder={t('common.search.placeholder_desktop')}
            className="inset-0 h-full w-full border-0 bg-transparent  outline-0 placeholder:text-ellipsis placeholder:text-gray-800"
            ref={inputRef}
          />
          {isSearchOpen && (
            <Icon
              name="close"
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                setSearchText('');
                inputRef.current?.focus();
              }}
              className={clsx('cursor-pointer text-gray-850', {
                invisible: !searchText
              })}
            />
          )}
        </div>
      </div>
      <div
        className={clsx(
          // 177px is the height of the header
          'absolute bottom-0 left-0 z-30 hidden h-screen w-screen translate-y-full bg-black opacity-75 lg:block',
          isSearchOpen ? 'visible opacity-100' : 'invisible opacity-0'
        )}
        role="button"
        onClick={() => dispatch(closeSearch())}
      />
      <div
        className={clsx(
          'invisible-overflow absolute left-0 top-[65px]  h-full min-h-[calc(100dvh-100px)] w-screen overflow-auto overflow-x-hidden overscroll-contain bg-white pb-10 pt-[15px] transition-opacity duration-100',
          'lg:left-1/2 lg:h-auto lg:min-h-fit lg:max-w-[856px] lg:-translate-x-1/2 lg:transform lg:pb-0',
          isSearchOpen
            ? 'visible !z-40 opacity-100'
            : 'invisible -z-10 opacity-0'
        )}
      >
        <div
          className={clsx(
            'mx-auto flex flex-col gap-6 bg-white',
            'lg:pb-6 lg:pt-0'
          )}
        >
          <div
            className={clsx(
              'flex flex-col items-center gap-2 self-center px-4',
              'md:w-full md:max-w-[636px]',
              'lg:hidden'
            )}
          >
            <div
              className={clsx(
                'relative flex items-center gap-4 border border-primary px-6',
                'md:w-full'
              )}
            >
              <Icon
                name="search"
                className={clsx(' cursor-pointer text-gray-850', '')}
                size={24}
              />
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  handleOnKeyDown(e);
                }}
                onClick={() => dispatch(openSearch())}
                className={clsx(
                  'h-10 w-full border-0 text-base capitalize text-primary outline-0',
                  'focus:outline-0 placeholder:lg:text-2xl'
                )}
                placeholder={t('common.search.placeholder')}
                ref={inputRefMobile}
              />
              <Icon
                name="close"
                size={24}
                onClick={() => {
                  setSearchText('');
                  inputRefMobile.current?.focus();
                }}
                className={clsx('cursor-pointer text-gray-850', {
                  invisible: !searchText
                })}
              />
            </div>
          </div>
          <Results searchText={searchText} />
          {searchHistory.length > 0 && (
            <div className="px-4 lg:px-6">
              <div className="mb-4 flex items-center justify-between gap-4">
                <span
                  className={clsx(
                    'text-lg font-medium text-primary',
                    'lg:text-2xl'
                  )}
                >
                  {t('category.search.previous_searchs')}
                </span>
                <Button
                  appearance="ghost"
                  onClick={() => {
                    localStorage.setItem('searchHistory', '[]');
                    setSearchHistory([]);
                  }}
                  className={clsx(
                    'hidden !h-8 border border-b-primary px-0 py-1 pb-0.5 text-sm text-primary',
                    'lg:flex'
                  )}
                >
                  {t('category.search.clear_previous_searchs')}
                </Button>
              </div>
              <div className={clsx('flex flex-wrap gap-2', '')}>
                {searchHistory.map((item, index) => {
                  return (
                    <div
                      className={clsx(
                        'flex items-center gap-2 bg-secondary-50 py-1 pl-4 pr-3 text-base font-medium text-secondary-400',
                        'lg:gap-3',
                        `${item}`
                      )}
                      key={`search-history-item-${index}`}
                    >
                      <Link href={`${ROUTES.LIST}?search_text=${item}`}>
                        {item}
                      </Link>
                      <Icon
                        onClick={() => removeSearchHistory(index)}
                        name="close"
                        className="cursor-pointer"
                        size={18}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {isSearchOpen && (
            <>
              <PopulerSearchKeywords />
              <SearchModalRecommendation />
            </>
          )}
        </div>
      </div>
    </>
  );
}
