'use client';

import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { setOpenedMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';
import { MenuItemType } from '@akinon/next/types';

import { Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';

interface NavbarProps {
  menu: MenuItemType[];
}

function NavbarItem(props) {
  return (
    <li
      className="group flex h-full items-center"
      onMouseEnter={() => {
        props.dispatch(setOpenedMenu(props.item.pk));
      }}
      onMouseLeave={() => {
        props.dispatch(setOpenedMenu(null));
      }}
    >
      <div
        className="flex items-center px-2.5 text-sm transition hover:text-secondary xl:text-base"
        data-testid="navbar-category"
        data-link={props?.item?.url}
      >
        {props?.item.extra_context?.attributes?.title_image?.kwargs?.url &&
        props?.item.extra_context?.attributes?.title_type?.value === 'image' ? (
          <Image
            src={
              props?.item?.extra_context?.attributes?.title_image?.kwargs
                ?.url ?? '/noimage.jpg'
            }
            alt={props.item.label}
            width={40}
            height={14}
            sizes="40px"
            fetchPriority="high"
            imageClassName="min-w-10 min-h-3.5 object-cover"
          />
        ) : props?.item?.extra_context?.attributes?.clickable?.value ===
          'true' ? (
          <Link
            className="py-[17px]"
            style={{
              color: props?.item?.extra_context?.attributes?.color?.value
            }}
            href={props?.item?.url}
          >
            {props?.item?.label}
          </Link>
        ) : (
          <span
            className="select-none py-[17px]"
            style={{
              color: props?.item?.extra_context?.attributes?.color?.value
            }}
          >
            {props?.item?.label}
          </span>
        )}
      </div>
      {/*
      Performance Note:
      The submenu content in this Navbar component is rendered based on hover-triggered state changes.
      This approach is adopted for performance optimization reasons. It ensures that:
      1. Submenu data is only loaded and rendered when necessary, reducing initial load times and resource usage.
      2. Unnecessary renders are avoided, enhancing the responsiveness and efficiency of the navigation bar.
      Please be cautious about altering this logic, as changes could negatively impact the performance and user experience of the Navbar.
    */}
      {props.item?.children?.length > 0 && (
        <div
          className={clsx(
            [
              'container',
              'absolute',
              'bottom-0',
              'left-0',
              'z-30',
              'flex flex-col',
              'justify-between',
              'invisible',
              'opacity-0',
              'bg-white',
              'border-x-2',
              'border-white',
              'pb-6',
              'transform',
              'translate-y-full'
            ],
            [
              'before:left-0',
              'before:-translate-x-full',
              'before:content-[""]',
              'before:w-1/2',
              'before:h-full',
              'before:block',
              'before:absolute',
              'before:top-0',
              'before:transform',
              'before:bg-white'
            ],
            [
              'after:right-0',
              'after:translate-x-full',
              'after:content-[""]',
              'after:w-1/2',
              'after:h-full',
              'after:block',
              'after:absolute',
              'after:top-0',
              'after:transform',
              'after:bg-white',
              'z-20'
            ],
            {
              '!visible !opacity-100': props.openedMenu === props.item.pk
            }
          )}
        >
          <div>
            <p className="py-4 text-2xl">
              {props.item?.extra_context?.attributes?.title?.value}
            </p>
            <div className="flex justify-between pb-6 pt-3">
              <div className="max-w-[901px] columns-4 gap-x-[60px]">
                {props.item.children.map((child, index) => (
                  <div key={index} className="break-inside-avoid">
                    <Link
                      onClick={() => {
                        props.dispatch(setOpenedMenu(null));
                      }}
                      href={child.url}
                      className={clsx(
                        'block w-max text-base font-semibold text-primary transition-colors hover:text-secondary lg:w-44',
                        props.item?.extra_context?.attributes?.color?.value
                          ? 'mb-4'
                          : 'mb-2'
                      )}
                    >
                      {child.extra_context.attributes.color?.value ? (
                        <span
                          style={{
                            color: child.extra_context.attributes.color?.value
                          }}
                        >
                          {child.label}
                        </span>
                      ) : (
                        child.label
                      )}
                    </Link>
                    {child.children && (
                      <ul>
                        {child.children.map((grandChild, index) => (
                          <li key={index}>
                            <Link
                              onClick={() => {
                                props.dispatch(setOpenedMenu(null));
                              }}
                              href={grandChild.url}
                              className="mb-3 block w-max text-sm font-medium text-gray-850 transition-colors hover:text-secondary lg:w-44"
                            >
                              {grandChild.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              {props?.item?.extra_context?.attributes?.images &&
                props?.openedMenu === props?.item?.pk &&
                props.item.extra_context.attributes.images.map(
                  (image, index) => (
                    <Link href={image?.value?.url} key={index}>
                      <Image
                        fetchPriority="low"
                        loading="lazy"
                        src={image?.kwargs?.value?.image?.url ?? '/noimage.jpg'}
                        alt={image?.kwargs?.value?.image?.url}
                        width={347}
                        height={336}
                        unoptimized
                        imageClassName="h-[336px]"
                      />
                    </Link>
                  )
                )}
            </div>
            {props.item?.extra_context?.attributes?.navigation_menu_footer?.every(
              (t) => !!t?.value?.title && !!t?.value?.url
            ) && (
              <div
                className={clsx(
                  'relative z-[5] flex justify-between border-lightBlue py-[15px]',
                  props.hasFooterItemBorder(
                    props.item,
                    props.borderType.borderY
                  ),
                  props.hasFooterItemBorder(
                    props.item,
                    props.borderType.borderR
                  ),
                  props.hasFooterItemBorder(
                    props.item,
                    props.borderType.borderAfter
                  )
                )}
              >
                <div className=" flex text-sm font-semibold text-primary">
                  {props.item?.extra_context?.attributes
                    ?.navigation_menu_footer &&
                    props.item.extra_context.attributes.navigation_menu_footer.map(
                      (footerItem, index) =>
                        footerItem.value.alignment === 'left' && (
                          <Link
                            key={index}
                            className="relative"
                            href={footerItem.value.url}
                          >
                            <span
                              className={
                                clsx(
                                  props.item.extra_context.attributes
                                    .navigation_menu_footer.length -
                                    2 !=
                                    index && [
                                    'after:block',
                                    'after:absolute',
                                    'after:-right-[17px]',
                                    'after:top-[10px]',
                                    'after:h-[1px]',
                                    'after:w-[50px]',
                                    'after:translate-x-full',
                                    'after:rotate-90',
                                    'after:bg-lightBlue',
                                    'after:content-[""]'
                                  ]
                                ) + (index === 0 ? '' : ' pl-[84px]')
                              }
                            >
                              {footerItem.value.title}
                            </span>
                          </Link>
                        )
                    )}
                </div>
                {props.item?.extra_context?.attributes
                  ?.navigation_menu_footer &&
                  props.item.extra_context.attributes.navigation_menu_footer.map(
                    (footerItem, index) =>
                      footerItem.value.alignment === 'right' && (
                        <Link
                          key={index}
                          className="text-sm font-normal text-primary underline underline-offset-2"
                          href={footerItem.value.url}
                        >
                          {footerItem.value.title}
                        </Link>
                      )
                  )}
              </div>
            )}
            <div
              className={clsx(
                'relative border-lightBlue py-[15px] ',
                props.item?.extra_context?.attributes?.navigation_menu_footer?.every(
                  (t) => !!t?.value?.title && !!t?.value?.url
                ) &&
                  props.item.extra_context.attributes.navigation_menu_footer
                    .map((footerItem) => footerItem.value)
                    .filter((value) => {
                      return (
                        typeof value !== 'object' ||
                        (typeof value === 'object' &&
                          Object.keys(value).length !== 0)
                      );
                    }).length !== 0
                  ? [
                      'z-[2] ml-[-645px] mt-[-50px] h-[30px] w-[2582px] border-t border-x-0 p-0 !pb-0'
                    ]
                  : ''
              )}
            ></div>
          </div>
        </div>
      )}
    </li>
  );
}

export default function Navbar(props: NavbarProps) {
  const { menu } = props;
  const dispatch = useAppDispatch();
  const { openedMenu } = useAppSelector((state) => state.header);
  const borderType = {
    borderY: 'border-y',
    borderL: 'border-l',
    borderR: 'border-r'
  };

  const hasFooterItemBorder = (item, border) => {
    const footerAttributes =
      item.extra_context.attributes.navigation_menu_footer;

    if (footerAttributes) {
      const hasNonObjectValues = footerAttributes.some((footerItem) => {
        const value = footerItem.value;
        return (
          typeof value !== 'object' ||
          (typeof value === 'object' && Object.keys(value).length !== 0)
        );
      });

      if (hasNonObjectValues) {
        if (border === borderType.borderL) {
          return 'border-l';
        }
      }
    }

    return '';
  };

  const openedMenuHasChildren =
    openedMenu &&
    menu?.find((menuItem) => menuItem?.pk == openedMenu)?.children?.length > 0;

  return (
    <>
      <nav
        className={clsx(
          'header-grid-area-nav relative hidden flex-wrap items-center justify-items-center lg:flex ',
          openedMenuHasChildren && [
            'before:left-0',
            'before:bottom-0',
            'before:-translate-x-full',
            'before:content-[""]',
            'before:h-[1px]',
            'before:w-full',
            'before:block',
            'before:absolute',
            'before:transform',
            'before:bg-lightBlue'
          ],
          openedMenuHasChildren && [
            'after:right-[110px]',
            'after:bottom-0',
            'after:translate-x-full',
            'after:content-[""]',
            'after:h-[1px]',
            'after:w-full',
            'after:block',
            'after:absolute',
            'after:transform',
            'after:bg-lightBlue',
            'z-30'
          ]
        )}
      >
        <ul
          className={clsx(
            'flex flex-1 flex-col justify-between gap-3  xl:flex-row',
            openedMenuHasChildren && 'border-b border-lightBlue'
          )}
        >
          <div
            className="flex items-center gap-x-3 "
            data-testid="left-nav-menu"
          >
            {menu?.map(
              (item, index) =>
                item.label != null &&
                item.extra_context.attributes.alignment?.value !== 'right' && (
                  <NavbarItem
                    key={index}
                    dispatch={dispatch}
                    openedMenu={openedMenu}
                    borderType={borderType}
                    hasFooterItemBorder={hasFooterItemBorder}
                    item={item}
                  ></NavbarItem>
                )
            )}
          </div>
          <div
            className="flex items-center gap-x-3 xl:justify-end"
            data-testid="right-nav-menu"
          >
            {menu.map(
              (item, index) =>
                item.label != null &&
                item.extra_context.attributes.alignment?.value === 'right' && (
                  <NavbarItem
                    key={index}
                    dispatch={dispatch}
                    openedMenu={openedMenu}
                    borderType={borderType}
                    hasFooterItemBorder={hasFooterItemBorder}
                    item={item}
                    link={true}
                  ></NavbarItem>
                )
            )}
          </div>
        </ul>
      </nav>
    </>
  );
}
