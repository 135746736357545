'use client';

import { signOut, useSession } from 'next-auth/react';
import { useLogoutMutation } from '@akinon/next/data/client/user';
import { ROUTES } from '@theme/routes';

import {
  Button,
  Icon,
  Link,
  Skeleton,
  SkeletonWrapper
} from '@theme/components';
import clsx from 'clsx';
import { useLocalization } from '@akinon/next/hooks';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

const ACCOUNT_MENU_ITEMS_MOBILE = [
  [
    {
      translationKey: 'account.base.menu.my_account',
      href: ROUTES.ACCOUNT,
      testId: 'account'
    },
    {
      translationKey: 'account.base.menu.my_orders',
      href: ROUTES.ACCOUNT_ORDERS,
      testId: 'account-orders'
    },
    // {
    //   translationKey: 'account.base.menu.messages',
    //   href: ROUTES.MESSAGES,
    //   testId: 'account-messages'
    // },
    {
      translationKey: 'account.base.menu.my_wishlist',
      href: ROUTES.ACCOUNT_WISHLIST,
      testId: 'account-wishlist'
    },
    {
      translationKey: 'account.base.menu.notifications',
      href: ROUTES.NOTIFICATIONS,
      testId: 'account-notifications'
    }
  ],
  [
    {
      translationKey: 'account.base.menu.my_profile',
      href: ROUTES.ACCOUNT_PROFILE,
      testId: 'account-profile'
    },
    {
      translationKey: 'account.base.menu.address_book',
      href: ROUTES.ACCOUNT_ADDRESS,
      testId: 'account-address'
    },
    {
      translationKey: 'account.base.menu.store_invoices',
      href: ROUTES.STORE_INVOICES,
      testId: 'account-store-invoices'
    }
  ],
  [
    {
      translationKey: 'account.base.menu.club_cards',
      href: ROUTES.CLUB_CARDS,
      testId: 'account-club-cards'
    },
    {
      translationKey: 'account.base.menu.gift_lists',
      href: ROUTES.GIFT_LISTS,
      testId: 'account-gift-lists'
    },
    // {
    //   translationKey: 'account.base.menu.reviews',
    //   href: ROUTES.REVIEWS,
    //   testId: 'account-reviews'
    // },
    {
      translationKey: 'account.base.menu.my_vouchers',
      href: ROUTES.ACCOUNT_COUPONS,
      testId: 'account-my-vouchers'
    }
  ],
  [
    // {
    //   translationKey: 'account.base.menu.digital_assistant',
    //   href: ROUTES.DIGITAL_ASSISTAN,
    //   testId: 'account-digital-assistant',
    //   highlighted: true
    // },
    {
      translationKey: 'account.base.menu.faq',
      href: ROUTES.ACCOUNT_FAQ,
      testId: 'account-faq'
    },
    {
      translationKey: 'account.base.menu.logout',
      href: ROUTES.ACCOUNT_CHANGE_EMAIL,
      testId: 'account-logout',
      onClick: (callback: () => void) => callback()
    }
  ]
];

const ACCOUNT_MENU_ITEMS_DESKTOP = [
  [
    {
      translationKey: 'account.base.menu.my_account',
      href: ROUTES.ACCOUNT,
      testId: 'account'
    },
    {
      translationKey: 'account.base.menu.my_orders',
      href: ROUTES.ACCOUNT_ORDERS,
      testId: 'account-orders'
    }
    // {
    //   translationKey: 'account.base.menu.messages',
    //   href: ROUTES.MESSAGES,
    //   testId: 'account-messages'
    // }
  ],
  [
    {
      translationKey: 'account.base.menu.my_profile',
      href: ROUTES.ACCOUNT_PROFILE,
      testId: 'account-profile'
    }
  ],
  [
    {
      translationKey: 'account.base.menu.club_cards',
      href: ROUTES.CLUB_CARDS,
      testId: 'account-club-cards'
    },
    {
      translationKey: 'account.base.menu.gift_lists',
      href: ROUTES.GIFT_LISTS,
      testId: 'account-gift-lists'
    },
    // {
    //   translationKey: 'account.base.menu.reviews',
    //   href: ROUTES.REVIEWS,
    //   testId: 'account-reviews'
    // },
    {
      translationKey: 'account.base.menu.my_vouchers',
      href: ROUTES.ACCOUNT_COUPONS,
      testId: 'account-my-vouchers'
    }
  ],
  [
    // {
    //   translationKey: 'account.base.menu.digital_assistant',
    //   href: ROUTES.DIGITAL_ASSISTAN,
    //   testId: 'account-digital-assistant',
    //   highlighted: true
    // },
    {
      translationKey: 'account.base.menu.faq',
      href: ROUTES.ACCOUNT_FAQ,
      testId: 'account-faq'
    },
    {
      translationKey: 'account.base.menu.logout',
      href: ROUTES.ACCOUNT_CHANGE_EMAIL,
      testId: 'account-logout',
      onClick: (callback: () => void) => callback()
    }
  ]
];

const ACCOUNT_MENU_ITEMS_UNAUTHORIZED = [
  [
    {
      translationKey: 'account.base.menu.signup',
      href: `${ROUTES.AUTH}?tab=register`,
      testId: 'account-signup'
    },
    {
      translationKey: 'account.base.menu.login',
      href: `${ROUTES.AUTH}?tab=login`,
      testId: 'account-login'
    }
  ],
  [
    {
      translationKey: 'account.base.menu.anonymous_tracking',
      href: ROUTES.ANONYMOUS_TRACKING,
      testId: 'account-anonymous'
    }
  ],
  [
    {
      translationKey: 'account.base.menu.gift_list',
      href: ROUTES.GIFT_LISTS,
      testId: 'account-mygift-list'
    },
    {
      translationKey: 'account.base.menu.faq',
      href: '/sss',
      testId: 'account-sss'
    }
  ]
];

type Props = {
  handleClose?: () => void;
};

export default function AccountMenu({ handleClose }: Props) {
  const { t, locale, defaultLocaleValue } = useLocalization();
  const [logout] = useLogoutMutation();
  const { status } = useSession();

  const handleLogout = async () => {
    logout()
      .unwrap()
      .then(() => {
        signOut({
          callbackUrl: '/'
        });
      })
      .catch((error) => console.error(error));
  };

  const { data, isLoading, isSuccess } = useGetWidgetQuery(
    'account-menu-campaign'
  );

  const campaign: { text: string; is_visible: 'true' | 'false' | null } = {
    text: data?.attributes?.text?.value,
    is_visible: data?.attributes?.is_visible?.value
  };

  return (
    <div
      className={clsx(
        'header-account-menu fixed right-0 top-0 z-[99]  h-full w-full overflow-y-auto bg-white transition duration-500',
        'lg:absolute lg:right-[-35px] lg:top-[88px] lg:h-auto lg:w-[342px] lg:overflow-y-hidden'
      )}
    >
      {status === 'loading' ? (
        <SkeletonWrapper className="px-4 lg:p-6">
          <Skeleton className="mb-2 h-5 w-full" />
          <Skeleton className="mb-2 h-5 w-full" />
          <div className="mb-2 h-[1px] bg-gray-200" />
          <Skeleton className="mb-2 h-5 w-full" />
          <Skeleton className="mb-2 h-5 w-full" />
          <Skeleton className="mb-2 h-5 w-full" />
        </SkeletonWrapper>
      ) : (
        <div>
          <div
            className={clsx(
              'flex h-16 items-center border-b border-lightBlue text-primary',
              'lg:hidden'
            )}
          >
            <Button
              onClick={() => {
                handleClose();
              }}
              appearance="ghost"
              className="flex items-center gap-2 text-sm leading-[1.43]"
            >
              <Icon name="close" className="text-primary" size={32} />{' '}
              {t('account.base.menu.my_account')}
            </Button>
          </div>

          <div className="px-6">
            {isLoading ? (
              <>
                <Skeleton type="text" className="mb-3 mt-4 h-[30px] w-full " />
              </>
            ) : (
              <>
                {isSuccess &&
                  campaign?.is_visible === 'true' &&
                  campaign?.text && locale === defaultLocaleValue && (
                    <div className="mb-3 mt-4 flex gap-2 bg-gray-100 p-2 ">
                      <div
                        className={clsx(
                          'mb-1 ml-2 mr-1 mt-1.5 h-[6px] w-[6px] flex-shrink-0 rounded-full',
                          'bg-secondary'
                        )}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{ __html: campaign?.text }}
                        className={clsx('text-xs text-primary')}
                      />
                    </div>
                  )}
              </>
            )}
          </div>

          {status === 'authenticated' ? (
            <>
              <div className="hidden px-6 pb-3 lg:block">
                {ACCOUNT_MENU_ITEMS_DESKTOP.map((item, index) => (
                  <ul
                    key={`account-menu-list-${index}`}
                    className={` border-b border-gray-200 last:border-b-0 ${index > 0 ? 'mt-3' : ''} `}
                  >
                    {item.map((item) => (
                      <li
                        key={item.testId}
                        className={clsx(
                          'mb-3 flex  items-center justify-between',
                          (item.href === ROUTES.NOTIFICATIONS && '!hidden') ||
                            (item.href === ROUTES.CLUB_CARDS && '!hidden')
                        )}
                      >
                        <Link
                          onClick={(e) => {
                            if (item.onClick) {
                              e.preventDefault();
                              item.onClick(handleLogout);
                            }
                          }}
                          href={item.href}
                          data-testid={item.testId}
                          className={clsx(
                            'text-sm font-medium leading-[1.43] text-gray-850'
                          )}
                        >
                          {t(item.translationKey)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
              <div className="px-6 pb-3 lg:hidden">
                {ACCOUNT_MENU_ITEMS_MOBILE.map((item, index) => (
                  <ul
                    key={`account-menu-list-mobile-${index}`}
                    className={`border-b border-gray-200 last:border-b-0 ${index > 0 ? 'mt-4' : ''} `}
                  >
                    {item.map((item) => (
                      <li
                        key={item.testId}
                        className={clsx(
                          'mb-3 flex  items-center justify-between',
                          (item.href === ROUTES.NOTIFICATIONS && '!hidden') ||
                            (item.href === ROUTES.CLUB_CARDS && '!hidden')
                        )}
                      >
                        <Link
                          onClick={(e) => {
                            handleClose();
                            if (item.onClick) {
                              e.preventDefault();
                              item.onClick(handleLogout);
                            }
                          }}
                          href={item.href}
                          data-testid={item.testId}
                          className={clsx(
                            'text-sm font-medium leading-[1.43] text-gray-850'
                          )}
                        >
                          {t(item.translationKey)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </>
          ) : (
            <div className="px-6 pb-3">
              {ACCOUNT_MENU_ITEMS_UNAUTHORIZED.map((item, index) => (
                <ul
                  key={`account-menu-unauthorized-${index}`}
                  className={` border-b border-gray-200 last:border-b-0 ${index > 0 ? 'mt-3' : ''} `}
                >
                  {item.map((item) => (
                    <li
                      key={item.testId}
                      className={clsx(
                        'mb-3 flex  items-center justify-between'
                      )}
                    >
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        href={item.href}
                        data-testid={item.testId}
                        className={clsx(
                          'text-sm font-medium leading-[1.43] text-gray-850'
                        )}
                      >
                        {t(item.translationKey)}
                      </Link>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
