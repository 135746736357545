'use client';

import {
  ReactNode,
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect
} from 'react';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import {
  closeMiniBasket
} from '@akinon/next/redux/reducers/root';
import { openSearch } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';
import { ROUTES } from '@theme/routes';

import MiniBasket from './mini-basket';
import { Badge, Icon, Link } from '@theme/components';
import { useOnClickOutside, useRouter } from '@akinon/next/hooks';
import AccountMenu from '../account/account-menu';

interface MenuItem {
  label: string;
  url?: string;
  action?: () => void;
  icon: string;
  className?: string;
  badge?: ReactNode;
  miniBasket?: ReactNode;
  dataTestId?: string;
  content?: ReactNode;
  events?: object;
}

export default function ActionMenu() {
  const dispatch = useAppDispatch();

  const { data } = useGetBasketQuery();
  const totalQuantity = useMemo(() => data?.total_quantity ?? 0, [data]);
  const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);

  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const miniBasketRef = useRef(null);
  const closeMiniBasketCb = useCallback(() => {
    if (miniBasketOpen) dispatch(closeMiniBasket());
  }, [miniBasketOpen, dispatch]);
  useOnClickOutside(miniBasketRef, closeMiniBasketCb);

  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
  const router = useRouter();
  const [isHovered, setIsHovered] = useState(false);

  const MenuItems: MenuItem[] = [
    {
      label: 'Search',
      action: () => {
        dispatch(openSearch());
      },
      icon: 'search',
      className: 'lg:hidden [&_i]:size-8',
      dataTestId: 'header-search'
    },
    {
      label: 'Login',
      url: ROUTES.ACCOUNT,
      icon: 'user',
      className: clsx(
        'h-full lg:!mx-0 lg:pl-10 lg:[&:focus-within_.header-account-menu]:hidden [&:not(:hover)_.header-account-menu]:hidden lg:[&:not(:hover)_.header-account-menu]:hidden [&_i]:size-8',
        '[&:hover_.header-account-menu]:block lg:[&:hover_.header-account-menu]:block',
        { '[&:hover_.header-account-menu]:hidden': !showAccountMenu }
      ),
      dataTestId: 'header-login',
      action: () => {
        router.push(ROUTES.ACCOUNT);
      },
      events: {
        onMouseEnter: () => {
          setShowAccountMenu(true);
        }
      },
      content: showAccountMenu && (
        <>
          <AccountMenu handleClose={() => setShowAccountMenu(false)} />
        </>
      )
    },
    {
      label: 'Favourite Products',
      url: ROUTES.ACCOUNT_WISHLIST,
      icon: 'heart-stroke',
      className: '[&_i]:size-8',
      dataTestId: 'header-favourite'
    },
    {
      label: 'Basket',
      action() {
        router.push(ROUTES.BASKET);
      },
      className: '[&_i]:size-8',
      icon: 'bag',
      dataTestId: 'header-basket',
      badge: (
        <Badge
          className={clsx(
            'flex-center -bottom-1 -right-1 top-auto h-5 w-5 bg-primary text-primary-foreground'
          )}
        >
          {totalQuantity}
        </Badge>
      ),
      miniBasket: <MiniBasket isHovered={isHovered} />
    }
  ];

  useEffect(() => {
    if (showAccountMenu) {
      document.body.classList.add('mobile-overflow-hidden');
    }
    return () => {
      document.body.classList.remove('mobile-overflow-hidden');
    };
  }, [showAccountMenu]);

  return (
    <ul
      className={clsx('flex h-full items-center gap-1 md:gap-3', {
        'hidden lg:flex': isSearchOpen
      })}
    >
      {MenuItems.map((menu, index) => (
        <li
          key={index}
          className={clsx('relative flex items-center', menu.className)}
          ref={menu.miniBasket ? miniBasketRef : null}
          onMouseEnter={
            menu?.label === 'Basket' ? () => setIsHovered(true) : null
          }
          onMouseLeave={
            menu?.label === 'Basket' ? () => setIsHovered(false) : null
          }
        >
          {menu.action ? (
            <button
              onClick={menu.action}
              {...menu?.events}
              data-testid={menu.dataTestId}
            >
              <Icon name={menu.icon} size={32} />
              {menu.badge}
            </button>
          ) : (
            <Link
              href={menu.url ?? '#'}
              passHref={true}
              onClick={(event) => {
                if (menu.action) {
                  event.preventDefault();
                  menu.action();
                }
              }}
              data-testid={menu.dataTestId}
            >
              <Icon name={menu.icon} size={32} />
              {menu.badge}
            </Link>
          )}
          {menu.content}
          {menu.miniBasket}
        </li>
      ))}
    </ul>
  );
}
