/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FooterSubscriptionFormType } from '@theme/types';
import { useEmailSubscriptionMutation } from '@akinon/next/data/client/misc';
import { Input, Button, Checkbox, Modal } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import { FooterSubscriptionPrivacyModal } from './privacy-modal';

const subscriptionFormSchema = (t: any) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('common.newsletter_signup.required_email'))
      .required(t('common.newsletter_signup.required')),
    subscribe_contract: yup
      .boolean()
      .oneOf([true], t('common.newsletter_signup.required'))
  });

export default function FooterSubscription() {
  const { t } = useLocalization();
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [emailSubscription] = useEmailSubscriptionMutation();

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FooterSubscriptionFormType>({
    resolver: yupResolver(subscriptionFormSchema(t))
  });

  const onSubmit: SubmitHandler<FooterSubscriptionFormType> = (data) => {
    emailSubscription(data)
      .unwrap()
      .then(() => {
        setIsSubscriptionModalOpen(false);
        setIsSuccessModalOpen(true);
        setValue('email', '');
      });
  };

  return (
    <>
      <p className="mb-4 font-medium text-white">
        {t('common.newsletter_signup.description')}
      </p>
      <Button
        type="submit"
        className="!h-10 w-[120px] bg-white px-4 py-2 text-primary hover:border-white hover:bg-primary hover:text-white"
        onClick={() => setIsSubscriptionModalOpen(true)}
      >
        {t('common.newsletter_signup.title')}
      </Button>
      <Modal
        portalId="footer-subscription-success-modal"
        title={t('common.newsletter_signup.success_title')}
        open={isSuccessModalOpen}
        setOpen={setIsSuccessModalOpen}
        showCloseButton={true}
        className="max-h-[90vh] w-full overflow-y-auto sm:w-[28rem]"
      >
        <p className="py-4 text-center">
          {t('common.newsletter_signup.success_description')}
        </p>
      </Modal>
      <Modal
        portalId="footer-subscription-modal"
        title={t('common.newsletter_signup.title')}
        open={isSubscriptionModalOpen}
        setOpen={setIsSubscriptionModalOpen}
        showCloseButton={true}
        className="max-h-[90vh] w-full overflow-y-auto sm:w-[28rem]"
      >
        <div className="p-6">
          <p className="mb-2">{t('common.newsletter_signup.description')}</p>
          <div className="flex justify-start">
            <Input
              id="footer-subscription-email"
              type="email"
              placeholder={t('common.newsletter_signup.email_address')}
              className="mb-3 !h-7 w-60 px-1 text-sm"
              {...register('email')}
              error={errors.email}
              data-testid="newsletter-input"
            />

            <Button
              type="submit"
              appearance="outlined"
              className="ml-2 !h-7 text-xs font-semibold"
              data-testid="newsletter-submit"
              onClick={handleSubmit(onSubmit)}
            >
              {t('common.newsletter_signup.submit')}
            </Button>
          </div>
          <div className="mb-4 flex justify-start">
            <Checkbox
              {...register('subscribe_contract')}
              error={errors.subscribe_contract}
              data-testid="newsletter-agreement-checkbox"
            >
              {t('common.newsletter_signup.accept')}{' '}
              <button
                type="button"
                className="underline"
                onClick={() => {
                  setIsPrivacyModalOpen(true);
                }}
                data-testid="newsletter-agreement"
              >
                {t('common.newsletter_signup.privacy_policy')}
              </button>
              {isPrivacyModalOpen && (
                <FooterSubscriptionPrivacyModal
                  isModalOpen={isPrivacyModalOpen}
                  setIsModalOpen={setIsPrivacyModalOpen}
                />
              )}
            </Checkbox>
          </div>
        </div>
      </Modal>
    </>
  );
}
