'use client';
import { Link, Accordion, Button } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization } from '@akinon/next/hooks';

type SideItem = {
  value: string;
  data_type: 'dropdown';
};

type TargetBlank = {
  value: string;
  data_type: 'dropdown';
};

type FooterQrCode = {
  kwargs: {
    url: string;
    data_type: 'image';
  };
};

type FooterQrCodeUrl = {
  value: string;
};

type FooterMenuItem = [
  {
    // TODO: Refactor this from commerce_proxy
    kwargs: {
      data_type: 'nested';
      value: {
        is_side_column_item?: SideItem;
        is_target_blank: TargetBlank;
      };
    };
    value: {
      is_side_column_item?: string;
      is_target_blank: string;
      name: string;
      redirect_url: string;
      hide_in_global: string;
    };
  }
];

type FooterMenuTitle = {
  value: string;
};

type FooterMenuType = {
  first_column_title: FooterMenuTitle;
  first_column_items: FooterMenuItem;
  second_column_title: FooterMenuTitle;
  second_column_items: FooterMenuItem;
  third_column_title: FooterMenuTitle;
  third_column_items: FooterMenuItem;
  fourth_column_title: FooterMenuTitle;
  fourth_column_items: FooterMenuItem;
  site_qr?: FooterQrCode;
  site_qr_url?: FooterQrCodeUrl;
};


export default function FooterMenu() {
  const { t, locale } = useLocalization();
  const { data } = useGetWidgetQuery('footer-menu', {});
  const [_, currentCountry] = locale.split('-');

  if (Object.keys(data?.attributes || {}).length === 0) return;

  return (
    <div className="flex-1">
      <div className="hidden justify-between lg:grid lg:grid-cols-12">
        <div className="col-span-3 flex flex-col">
          <div
            className="mb-5 font-semibold text-primary"
            data-testid="footer-help"
          >
            {data?.attributes?.first_column_title?.value}
          </div>
          <ul className="">
            {data?.attributes?.first_column_items?.map((item, i) => (
              <li className="mb-3" key={i}>
                <Link
                  className="whitespace-nowrap text-primary"
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                  data-testid={`footer-categories-${item?.value?.name
                    ?.toLocaleLowerCase()
                    .split(' ')
                    .join('')}`}
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
            {data?.attributes?.site_qr_url?.value &&
              data?.attributes?.site_qr?.kwargs?.url && (
                <li>
                  <Link
                    className="whitespace-nowrap text-primary"
                    href={data?.attributes?.site_qr_url.value ?? '#'}
                    target="_blank"
                    data-testid="footer-qr-code"
                  >
                    <Image
                      className="mt-2 h-[64px] w-[56px]"
                      width={56}
                      height={64}
                      alt="QR Code"
                      src={data?.attributes?.site_qr?.kwargs?.url}
                    />
                  </Link>
                </li>
              )}
          </ul>
        </div>

        <div className="col-span-3 flex flex-col">
          <div
            className="mb-5 font-semibold text-primary"
            data-testid="footer-brands"
          >
            {data?.attributes?.second_column_title?.value}
          </div>
          <ul className="">
            {data?.attributes?.second_column_items?.map((item, i) => {
              if (
                item?.value?.hide_in_global === 'true' &&
                currentCountry !== 'tr'
              )
                return null;
              return (
                <li className="mb-3" key={i}>
                  <Link
                    className="whitespace-nowrap text-primary"
                    href={item?.value?.redirect_url}
                    target={
                      item?.value?.is_target_blank === 'true'
                        ? '_blank'
                        : '_self'
                    }
                    data-testid={`footer-categories-${item?.value?.name
                      ?.toLocaleLowerCase()
                      .split(' ')
                      .join('')}`}
                  >
                    {i === data.attributes.second_column_items.length - 1 ? (
                      <Button
                        appearance="outlined"
                        className="mt-2 min-w-56 focus:bg-transparent"
                      >
                        {' '}
                        {item?.value?.name}
                      </Button>
                    ) : (
                      item?.value?.name
                    )}
                  </Link>
                </li>
              );
            })}
            <li className="mb-3">
              <Button
                appearance="outlined"
                className="efl-edit min-w-56 focus:bg-transparent"
              >
                {t('common.cookie_preferences')}
              </Button>
            </li>
          </ul>
        </div>

        <div className="col-span-3 flex flex-col">
          <div
            className="mb-5 font-semibold text-primary"
            data-testid="footer-institutional"
          >
            {data?.attributes?.third_column_title?.value}
          </div>
          <ul className="">
            {data?.attributes?.third_column_items?.map((item, i) => (
              <li className="mb-3" key={i}>
                <Link
                  className="whitespace-nowrap text-primary"
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                  data-testid={`footer-categories-${item?.value?.name
                    ?.toLocaleLowerCase()
                    .split(' ')
                    .join('')}`}
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="col-span-3 flex flex-col">
          <div
            className="mb-5 font-semibold text-primary"
            data-testid="footer-categories"
          >
            {data?.attributes?.fourth_column_title?.value}
          </div>
          <ul className="">
            {data?.attributes?.fourth_column_items?.map((item, i) => (
              <li className="mb-3" key={i}>
                <Link
                  className="text-primary"
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                  data-testid={`footer-categories-${item?.value?.name
                    ?.toLocaleLowerCase()
                    .split(' ')
                    .join('')}`}
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="block lg:hidden">
        <Accordion
          title={data?.attributes?.first_column_title?.value}
          titleClassName="font-semibold"
          className="border-b border-lightBlue px-4 py-6"
          childrenClassName="ml-0 mt-4"
          icons={['minus', 'plus']}
          iconSize={13}
          iconClassName="flex items-center"
        >
          <ul>
            {data?.attributes?.first_column_items?.map((item, i) => (
              <li
                className={clsx(
                  i == data.attributes.first_column_items.length - 1
                    ? 'mb-0'
                    : 'mb-3'
                )}
                key={i}
              >
                <Link
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
          </ul>
        </Accordion>
        <Accordion
          title={data?.attributes?.second_column_title?.value}
          titleClassName="font-semibold"
          className="border-b border-lightBlue px-4 py-6"
          childrenClassName="ml-0 mt-4"
          icons={['minus', 'plus']}
          iconSize={13}
          iconClassName="flex items-center"
        >
          <ul>
            {data?.attributes?.second_column_items?.map((item, i) => {
              if (
                item?.value?.hide_in_global === 'true' &&
                currentCountry !== 'tr'
              )
                return null;
              return (
                <li
                  className={clsx(
                    i == data.attributes.second_column_items.length - 1
                      ? 'mb-0'
                      : 'mb-3'
                  )}
                  key={i}
                >
                  <Link
                    href={item?.value?.redirect_url}
                    target={
                      item?.value?.is_target_blank === 'true'
                        ? '_blank'
                        : '_self'
                    }
                  >
                    {i === data.attributes.second_column_items.length - 1 ? (
                      <Button appearance="outlined" className="mt-2 min-w-56">
                        {' '}
                        {item?.value?.name}
                      </Button>
                    ) : (
                      item?.value?.name
                    )}
                  </Link>
                </li>
              );
            })}
            <li className="mb-3">
              <Button appearance="outlined" className="efl-edit mt-2 min-w-56">
                {t('common.cookie_preferences')}
              </Button>
            </li>
          </ul>
        </Accordion>
        <Accordion
          title={data?.attributes?.third_column_title?.value}
          titleClassName="font-semibold"
          className="border-b border-lightBlue px-4 py-6"
          childrenClassName="ml-0 mt-4"
          icons={['minus', 'plus']}
          iconSize={13}
          iconClassName="flex items-center"
        >
          <ul>
            {data?.attributes?.third_column_items?.map((item, i) => (
              <li
                className={clsx(
                  i == data.attributes.third_column_items.length - 1
                    ? 'mb-0'
                    : 'mb-3'
                )}
                key={i}
              >
                <Link
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
          </ul>
        </Accordion>
        <Accordion
          title={data?.attributes?.fourth_column_title?.value}
          titleClassName="font-semibold"
          className="px-4 py-6"
          childrenClassName="ml-0 mt-4"
          icons={['minus', 'plus']}
          iconSize={13}
          iconClassName="flex items-center"
        >
          <ul>
            {data?.attributes?.fourth_column_items?.map((item, i) => (
              <li
                className={clsx(
                  i == data.attributes.fourth_column_items.length - 1
                    ? 'mb-0'
                    : 'mb-3'
                )}
                key={i}
              >
                <Link
                  href={item?.value?.redirect_url}
                  target={
                    item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
                  }
                >
                  {item?.value?.name}
                </Link>
              </li>
            ))}
          </ul>
        </Accordion>
      </div>
    </div>
  );
}
